<template>
  <div class="home-con">
    <van-form @submit="onSubmit" style="margin-top:10px">
      <van-field name="search_type" label="搜索类型">
        <template #input>
          <van-radio-group v-model="config.search_type" direction="horizontal">
            <van-radio name="username">用户名</van-radio>
            <van-radio name="email">邮箱</van-radio>
            <van-radio name="taobao_account">下单账号</van-radio>
            <van-radio name="activation_code">激活码</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="config.key"
        name="key"
        label="关键字"
        placeholder="输入关键字"
        :rules="[{ required: true, message: '请填写关键字' }]"
      />

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">获取</van-button>
      </div>
    </van-form>
    <div v-show="user.username">
      <van-cell-group title="用户信息" title-class="cell-title">
        <van-cell title-class="cell-title" center title="用户名" :value="user.username" :is-link="false"/>
        <van-cell title-class="cell-title" center title="密码" :value="user.password" :is-link="false"/>
        <van-cell title-class="cell-title" center title="邮箱" :value="user.email" :is-link="false"/>
        <van-cell title-class="cell-title" center title="下单账号" :value="user.taobao_account" />
        <van-cell title-class="cell-title" center title="允许设备数(windows)" 
        v-if="user.code_platform == 'all' || user.code_platform == 'win32' || user.platform_num==2" 
        is-link :value="user.allow_device_num" @click="modifyDeviceNumHandle('win32')" />

        <van-cell title-class="cell-title" center title="允许设备数(macos)" 
        v-if="user.code_platform == 'all' || user.code_platform == 'darwin' || user.platform_num==2" 
        is-link :value="user.allow_device_num_darwin" @click="modifyDeviceNumHandle('darwin')" />

        <van-cell title-class="cell-title" center title="开通双平台" 
        :label="'已激活' + (user.platform_num==1?user.code_platform:'双平台')"
        :is-link="user.platform_num < 2" :value="user.platform_num" @click="modifyPlatformNumHandle()"/>
        <van-cell title-class="cell-title" center title="绑定激活码" :value="user.activation_code" />
        <van-cell title-class="cell-title" center title="激活时长" :value="user.time_limit" />
        <van-cell title-class="cell-title" center title="已经使用时长" :value="remainTime(user.used_time)" />
        <van-cell title-class="cell-title" center title="注册时间" :value="user.registered_time" />
        <van-cell title-class="cell-title" center title="最近登录时间" :value="user.recent_login_time" />
        <van-cell title-class="cell-title" center title="注册平台" :value="user.register_platform" />
      </van-cell-group>

    </div>

    <!-- 激活码信息 -->
    <div v-show="code_info.platform">
      <van-cell-group title="激活码信息" title-class="cell-title">
          <van-cell title-class="cell-title" center :title="'时间 '+ (code_info.time_limit==0?'永久':code_info.time_limit) + ' /平台 '+code_info.platform+ ' /设备数 '+ code_info.allow_device_num" 
          :value="code_info.is_used==1?'已使用':'未使用'"
          :label="code_info.generate_time_str +'/'+ code_info.used_time_str"
           />
      </van-cell-group>
    </div>

    <div v-show="devices_list.length>0">
      <van-cell-group title="设备列表" title-class="cell-title">
        <template v-for="(item,index) in devices_list">
          <van-cell :key="index" title-class="cell-title" center :title="item.platform+'/'+item.time" 
          :value="item.m_model_identifier+'/'+item.w_computer_name" />
        </template>
      </van-cell-group>
    </div>

    <van-popup v-model="show_allow_device_num" position="bottom" :style="{ height: '50%',textAlign:'center'}">
      <van-row type="flex" style="height:40px" align="center">
        <van-col span="8">添加设备数</van-col>
        <van-col span="0"></van-col>
        <van-col span="0"></van-col>
      </van-row>
      <div v-if="setWin32DeviceInfo.one_device_price != null">
        <van-stepper v-model="setWin32DeviceInfo.add_device_num" theme="round"/>
        <van-row type="flex" style="height:40px;margin-top:20px" align="center">
          <van-col span="8">单价：{{setWin32DeviceInfo.one_device_price}}</van-col>
          <van-col span="8">折扣：{{setWin32DeviceInfo.discount}}</van-col>
          <van-col span="0">总计：{{
            Math.round( setWin32DeviceInfo.one_device_price * setWin32DeviceInfo.discount * setWin32DeviceInfo.add_device_num *100 ) / 100
            }}</van-col>
        </van-row>
        <van-button plain round type="primary" style="margin-top:20px" @click="modifyDeviceNumByPaid">确定</van-button>
      </div>
      <div v-if="!setWin32DeviceInfo.one_device_price">
        <span>
          无法设置，联系管理员
        </span>
      </div>
    </van-popup>

    <!-- 开通双平台 -->
    <van-popup v-model="show_platform_num" position="bottom" :style="{ height: '50%',paddingTop:'10%',textAlign:'center' }">
      <van-row type="flex" style="height:40px" align="center">
        <van-col span="8">开通双平台</van-col>
        <van-col span="0"></van-col>
        <van-col span="0"></van-col>
      </van-row>
      <div v-if="setWin32DeviceInfo.price != null">
        <!-- <van-stepper v-model="setWin32DeviceInfo.add_device_num" theme="round"/> -->
        <van-row type="flex" style="height:40px;margin-top:20px" align="center">
          <van-col span="8">单价：{{setWin32DeviceInfo.price}}</van-col>
          <van-col span="8">折扣：{{setWin32DeviceInfo.discount}}</van-col>
          <van-col span="0">总计：{{
            Math.round( setWin32DeviceInfo.one_device_price * setWin32DeviceInfo.discount *100 ) / 100
            }}</van-col>
        </van-row>
        <van-button plain round type="primary" style="margin-top:20px" @click="modifyDeviceNumByPaid">确定</van-button>
      </div>
      <div v-if="!setWin32DeviceInfo.price">
        <span>
          无法设置，联系管理员
        </span>
      </div>
    </van-popup>

  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'Home',
  data(){
    return {
      show_allow_device_num:false,
      show_platform_num:false,
      config:{
        search_type:'username',
        key:'',
      },
      devices_list:[],
      user:{},
      code_info:{},
      //上次提交查询内容
      subV:null,
      setWin32DeviceInfo:{
        add_device_num:1,
        one_device_price:null,
        price:null,
        discount:0.6,
        platform:'',
      }, 
      setDarwinDeviceNum:0 
    }
  },
  components: {

  },
  methods:{
    //修改平台数
    modifyPlatformNumHandle(){

      if(this.user.platform_num >=2){
        // Toast.fail('已经开通双平台');
        return;
      }

      let platform = '';
      if( this.user.code_platform == 'win32' ){
        platform = 'darwin'
      }else if( this.user.code_platform == 'darwin' ){
        platform = 'win32'
      }
      
      //获取展示价格
      api.code_type.searchOne({platform,time_limit:this.user.time_limit}).then(res=>{
          let r = res.data;
          if(r.code == 1){
            this.setWin32DeviceInfo.price = r.data.price
            this.setWin32DeviceInfo.discount = this.$store.state.user.discount
            this.setWin32DeviceInfo.platform = platform
            this.setWin32DeviceInfo.add_platform = true  //修改平台数
            this.show_platform_num=true;
          }else{
              console.log(res)
              Toast.fail(r.msg);
          }
      }).catch(err=>{
        Toast.fail('获取信息错误');
      })
    },
    //修改设备数
    modifyDeviceNumHandle(platform){
      api.code_type.searchOne({platform,time_limit:this.user.time_limit}).then(res=>{
          let r = res.data;
          if(r.code == 1){
            delete this.setWin32DeviceInfo['add_platform']; //非修改平台数
            this.setWin32DeviceInfo.one_device_price = r.data.one_device_price
            this.setWin32DeviceInfo.discount = this.$store.state.user.discount
            this.setWin32DeviceInfo.platform = platform
            this.show_allow_device_num=true;
          }else{
              console.log(res)
              Toast.fail(r.msg);
          }
      }).catch(err=>{
        Toast.fail('获取信息错误');
      })
    },
    //付费修改用户设备数
    modifyDeviceNumByPaid() {
      let values = this.setWin32DeviceInfo
      Object.assign(values,{user_id:this.user.id})

      Dialog.confirm({
        title: `确认支付`,
        message: `确认后将扣除您的预付金额`,
      }).then(() => {
        api.user.modifyUserInfoByPaid(values).then(res=>{
            let r = res.data;
            if(r.code == 1){
              console.log(res)
              Toast.success(r.msg);
              setTimeout(()=>{
                if(this.subV) this.onSubmit(this.subV)
              },2000)
            }else{
                Toast.fail(r.msg);
            }
            this.show_allow_device_num = false
        }).catch(err=>{
          console.error(err)
          Toast.fail('修改失败');
          this.show_allow_device_num = false
        })

      })
      .catch(() => {
        // on cancel
      });


    },
    remainTime(used_time = 0){
      if(used_time && !isNaN(used_time) && used_time!=0){
        let time =(new Date).getTime()/1000 - parseInt( used_time )
        if (null != time && "" != time) {
            if (time > 60 && time < 60 * 60) {
                time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) -
                    parseInt(time / 60.0)) * 60) + "秒";
            }
            else if (time >= 60 * 60 && time < 60 * 60 * 24) {
                time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
                    parseInt(time / 3600.0)) * 60) + "分钟" +
                    parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                    parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
            } else if (time >= 60 * 60 * 24) {
                time = parseInt(time / 3600.0/24) + "天" +parseInt((parseFloat(time / 3600.0/24)-
                    parseInt(time / 3600.0/24))*24) + "小时" + parseInt((parseFloat(time / 3600.0) -
                    parseInt(time / 3600.0)) * 60) + "分钟" +
                    parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                    parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
            }
            else {
                time = parseInt(time) + "秒";
            }
        }
        return time;
      }
      return '未知';
    },
    onClickLeft(){
      this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    copyCode(index,code){
      const input = document.getElementById(`input${index}`);
      input.value = code;
      // 聚焦
      input.focus();
      // 选择需要复制的文本
      if (input.setSelectionRange) {
          input.setSelectionRange(0, input.value.length);
      } else {
          input.select();
      }
      try {
          const result = document.execCommand('copy');
          if(result){
            this.$toast.success('复制激活码成功')
          }
      } catch (e) {
        this.$toast.fail('复制失败，请重试~')
      }
    },
    onSubmit(values) {
      this.subV = values
      Toast('提示内容');
      api.devices.getUserDevices(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('获取成功');
            this.devices_list = r.data.devices || []
            this.user = r.data.user || {}
            this.code_info = r.data.code_info || {}
          }else{
              console.log(res)
              Toast.fail(r.msg);
          }
      }).catch(err=>{
        Toast.fail('获取失败');
      })
    },

    deleteDev(item){
      //删除设备绑定
      Dialog.confirm({
        title: `确认删除?${item.platform}/${item.time}`,
        message: `${item.m_model_identifier}/${item.w_computer_name}`,
      })
        .then(() => {
          let values = {dev_id:item.id}
          values['token'] = 'lizheng666'
          api.devices.deleteDev(values).then(res=>{
              let r = res.data;
              if(r.code == 1){
                console.log(res)
                Toast.success('删除成功');
                if(this.subV) this.onSubmit(this.subV)
              }else{
                  Toast.fail('获取失败');
              }
          }).catch(err=>{
            Toast.fail('删除失败');
          })
        })
        .catch(() => {
          // on cancel
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.home-con{
  .cell-title{
    text-align: left;
  }

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
  .van-radio{
    margin-bottom:5px
  }
  .copy-input{
    width: 1px;
    resize: none;
    border: none;
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
  }
}
</style>